import uniavan from '../../assets/image/agency/blog/uniavantis.jpg';
import betricks from '../../assets/image/agency/blog/betricks.png';
import datainfo from '../../assets/image/agency/blog/datainfo.png';
import odontologia from '../../assets/image/agency/blog/odontologia.png';
import cnec from '../../assets/image/agency/blog/cnec.png';
import omega from '../../assets/image/agency/blog/omega.png';
import linx from '../../assets/image/agency/blog/linx.jpg';
import troquefacil from '../../assets/image/agency/blog/troquefacil.jpg';
import Member1 from '../../assets/image/agency/team/member-1.jpg';
import Member2 from '../../assets/image/agency/team/member-2.jpg';
import Member3 from '../../assets/image/agency/team/member-3.jpg';
import Denny from '../../assets/image/agency/client/denny.png';
import Menny from '../../assets/image/agency/client/menny.png';

const data = {
  menuItems: [
    {
      label: 'Serviços',
      path: '#featureSection',
      offset: '80',
    },
    {
      label: 'Experiência',
      path: '#workHistorySection',
      offset: '100',
    },
    {
      label: 'Portfólio',
      path: '#blogSection',
      offset: '100',
    },
    {
      label: 'Sobre mim',
      path: '#qualitySection',
      offset: '100',
    },
    {
      label: 'Perguntas',
      path: '#faqSection',
      offset: '100',
    },
  ],
  aboutus: [
    {
      id: 1,
      title: 'Comunicação contínua com todos envolvidos nos projetos',
      icon: 'flaticon-next',
    },
    {
      id: 2,
      title: 'Melhores tendências do mercado em tecnologia e inovação',
      icon: 'flaticon-next',
    },
    {
      id: 3,
      title: 'Treinamento e acompanhamento de processos.',
      icon: 'flaticon-next',
    },
    {
      id: 4,
      title: 'Suporte eterno sobre os produtos desenvolvidos',
      icon: 'flaticon-next',
    },
  ],
  features: [
    {
      id: 1,
      icon: 'flaticon-flask',
      title: 'Otimização de pesquisa',
      description:
        'Usando o Search Engine Optimization, você obterá mais clientes',
    },
    {
      id: 2,
      icon: 'flaticon-pencil-case',
      title: 'Ui/UX Design',
      description:
        'Fornecemos o melhor design de UI / UX seguindo as últimas tendências do mercado',
    },
    {
      id: 3,
      icon: 'flaticon-ruler',
      title: 'Projetos sob medida',
      description:
        'Analisamos e chegamos ao ponto exato que nossos clientes precisam',
    },
    {
      id: 4,
      icon: 'flaticon-startup',
      title: 'Soluções de negócio',
      description:
        'Estamos comprometidos em fornecer soluções comerciais adequadas com preços razoáveis',
    },
    {
      id: 5,
      icon: 'flaticon-briefing',
      title: 'Análise de negócio',
      description:
        'Um relatório diário sobre seus negócios em andamento para o entendimento adequado',
    },
    {
      id: 6,
      icon: 'flaticon-creative',
      title: 'Gerenciamento de conteúdo',
      description:
        'O gerenciamento adequado de seus conteúdo',
    },
  ],
  qualityFeature: [
    {
      id: 1,
      icon: 'flaticon-flask',
      title: 'Graduação',
      description:
        'Graduado em Informática pela CNEC Osório/RS, e pos graduação na Uniavan/SC.',
    },
    {
      id: 2,
      icon: 'flaticon-pencil-case',
      title: 'Tecnologias',
      description:
        'Me considero Fullstack, atuo com as mais diversas frentes a fim de trazer a melhor solução.',
    },
    {
      id: 3,
      icon: 'flaticon-ruler',
      title: 'Design',
      description:
        'Busco seguir oas melhores tendencias do mercado aliadas a idéia inicial do cliente .',
    },
    {
      id: 4,
      icon: 'flaticon-briefing',
      title: 'Padrão de código',
      description:
        'Acoompanho as tendências das comunidades em questão de padronização de códigos, tudo para facilitar para que novos membros possam trabalhar juntos no projeto .',
    },
  ],
  blog: [
    {
      id: 1,
      title: 'UniAvan',
      thumbnail_url: uniavan,
      date: 'Sites, sistemas interno e aplicativos',
      postLink: 'http://avantis.edu.br',
    },
    {
      id: 2,
      title: 'Betricks.',
      thumbnail_url: betricks,
      date: 'Sites, sistemas interno e aplicatios',
      postLink: 'http://www.betricks.com.br/',
    },
    {
      id: 3,
      title: 'Datainfo',
      thumbnail_url: datainfo,
      date: 'Sitesmas',
      postLink: 'http://datainfo.inf.br',
    },
    {
      id: 4,
      title: 'Odontologia',
      thumbnail_url: odontologia,
      date: 'Sistema interno de agendamento e cadastros',
      postLink: 'https://odontologiamorescas.com',
    },
    {
      id: 5,
      title: 'Rede CNEC',
      thumbnail_url: cnec,
      date: 'Sistema internos e sites',
      postLink: 'http://www.cnec.br/',
    },
    {
      id: 6,
      title: 'Omega contabilidade',
      thumbnail_url: omega,
      date: 'Site',
      postLink: 'http://omegacont.com.br',
    },
    {
      id: 7,
      title: 'Linx Blumenau',
      thumbnail_url: linx,
      date: 'Aplicativo e integrações',
      postLink: 'https://www.linx.com.br/',
    },
    {
      id: 8,
      title: 'Troque fácil',
      thumbnail_url: troquefacil,
      date: 'Sistemas internos',
      postLink: 'https://troquefacil.com.br',
    },
  ],
  teamMember: [
    {
      id: 1,
      name: 'Jessica Fanddy',
      thumbnail_url: Member1,
      designation: 'Co Founder',
      social_links: [
        'flaticon-facebook-logo',
        'flaticon-twitter-logo-silhouette',
        'flaticon-instagram',
        'flaticon-dribble-logo',
      ],
    },
    {
      id: 2,
      name: 'Devid Justingul',
      thumbnail_url: Member2,
      designation: 'Senior Ui/UX Designer',
      social_links: [
        'flaticon-twitter-logo-silhouette',
        'flaticon-instagram',
        'flaticon-facebook-logo',
        'flaticon-dribble-logo',
      ],
    },
    {
      id: 3,
      name: 'Handdy Albuzz',
      thumbnail_url: Member3,
      designation: 'Article Writter',
      social_links: [
        'flaticon-dribble-logo',
        'flaticon-twitter-logo-silhouette',
        'flaticon-instagram',
        'flaticon-facebook-logo',
      ],
    },
  ],
  testimonial: [
    {
      id: 1,
      name: 'Denny Albuz',
      designation: 'CEO of Denish Co.',
      comment:
        'Best working experience  with this amazing team & in future, we want to work together',
      avatar_url: Denny,
    },
    {
      id: 2,
      name: 'Roman Ul Oman',
      designation: 'Co-founder of QatarDiaries',
      comment:
        'Impressed with master class support of the team and really look forward for the future.',
      avatar_url: Menny,
    },
    {
      id: 3,
      name: 'Caroleane Mina',
      designation: 'Director of Beauty-queen',
      comment:
        'I have bought more than 10 themes on ThemeForest, and this is the first one I review. Wow! Amazing React Theme',
      avatar_url: Denny,
    },
    {
      id: 4,
      name: 'Roman Ul Oman',
      designation: 'Co-founder of QatarDiaries',
      comment:
        'Impressed with master class support of the team and really look forward for the future.',
      avatar_url: Menny,
    },
  ],
  faq: [
    {
      id: 1,
      expend: true,
      title: 'Como entrar em contato com o Atendimento ao Cliente?',
      description:
        'Estamos sempre online! Todos clientes tem os contatos mais diretos seja por email ou celular, para mandar um whats ou ligar com transparencia e disponibilidade. ',
    },
    {
      id: 2,
      title: 'Por quanto tempo tenho suporte ao serviço desenvolvido?',
      description:
        'Fico totalmente responsável a dar suporte em quesitos tecnicos sobre os produtos desenvolvidos e entregues, isso fica sempre em uma clausula de contrato resguardando o cliente. ',
    },
    {
      id: 3,
      title: 'Como entro em contato?',
      description:
        'Você pode contatar pelas redes sociais, ou pelos contatos henriqueweiand@gmail.com ou 47 99240-2547',
    },
    {
      id: 4,
      title: 'Podemos agendar uma conversa?',
      description:
        'Sim! Muitas pessaos gostam de resolver ou fechar negócios presencialmente e isso é muito bom! Vamos tomar um café e debatar mais sua idéia a fim de contruir um resultado juntos. Entre em contato que será bem recebido.',
    },
  ],
  menuWidget: [
    {
      id: 1,
      title: 'Redes sociais',
      menuItems: [
        {
          id: 1,
          url: 'https://www.facebook.com/h.weiand',
          text: 'Facebook',
        },
        {
          id: 2,
          url: 'https://www.linkedin.com/in/henrique-weiand',
          text: 'Linkedin',
        },
        {
          id: 3,
          url: 'https://www.instagram.com/henriqueweiand/',
          text: 'Instagram',
        },
        {
          id: 4,
          url: 'https://github.com/henriqueweiand',
          text: 'Github',
        },
      ],
    },
    {
      id: 2,
      title: 'Blog',
      menuItems: [
        {
          id: 1,
          url: 'https://medium.com/@henrique.weiand',
          text: 'Medium',
        },
      ],
    },
  ],
  social_profile: [
    {
      id: 1,
      icon: 'flaticon-facebook-logo',
      link: 'https://www.facebook.com/h.weiand',
    },
    {
      id: 3,
      icon: 'flaticon-instagram',
      link: 'https://www.instagram.com/henriqueweiand/',
    },
    {
      id: 5,
      icon: 'flaticon-conversation',
      link: 'https://medium.com/@henrique.weiand',
    },
  ],
};
export default data;
